<template>
  <div>
    <v-card :loading="loading" class="mx-auto" flat>
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="2" indeterminate></v-progress-linear>
      </template>
      <connections-item v-if="Object.keys(connections_out).length" :connections="connections_out">
        <template slot="sub-heading">Referencing:</template>
      </connections-item>
      <connections-item v-if="Object.keys(connections_in).length" :connections="connections_in">
        <template slot="sub-heading">Referenced By:</template>
      </connections-item>
      <v-card-text v-if="(!Object.keys(connections_out).length && !Object.keys(connections_in).length) && showNoItemsMessage">
        no references.
      </v-card-text>
    </v-card>
    
    <debug v-if="debug">
      <template slot="sub-heading">Connections</template>
      <template slot="info">
        connections_in={{ connections_in }}<br/>
        connections_out={{ connections_out }}
      </template>
    </debug>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import helperUtils from '@/helpers/utils'

import DocAdmin from '@/store/data'
import Debug from '@/components/common/Debug'
import ConnectionsItem from '@/components/connections/ConnectionsItem'

export default {
  name: 'Connections',
  components: {
    ConnectionsItem,
    Debug
  },
  props: {
    sourceId: {
      type: Number,
      default: null
    },
    connectionTypeId: {
      type: Number,
      default: null
    },
    showNoItemsMessage: {
      type: Boolean,
      default: false
    },
    languageId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    connections_in: {},
    connections_out: {},
    loading: true
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapState('app', ['debug'])
  },
  mounted() {
    this.getConnections()
  },
  methods: {
    groupByKey(c, key) {
      return _.mapValues(_.groupBy(c, key), (clist) => clist.map((conn) => _.omit(conn, key)))
    },
    updateConnections(data) {
      Object.keys(data).forEach((l1) => {
        data[l1].forEach((con) => {
          con.url = helperUtils.generateUrlFromBreadcrumb(con.id, con.rootid, con.breadcrumb, con.connectiontypeid)
          con.title = helperUtils.generateTitleFromBreadcrumb(con.connectiontypeid, con.breadcrumb, con.title)
          if (l1 === 'outbound_links') {
            con.title = helperUtils.generateTitleWithDocumentItemType(con.connectiontypeid, con.breadcrumb, con.title)
          }
        })
      })

      return data
    },
    getConnections () {
      DocAdmin.connectionsInOut.list({ params: { id: this.sourceId, connectionTypeId: this.connectionTypeId } }).then((response) => {
        this.loading = false
        const data = this.updateConnections(response.data.data)

        this.connections_in = this.groupByKey(data.inbound_links, 'connectiontypeid')
        this.connections_out = this.groupByKey(data.outbound_links, 'connectiontypeid')
      }, (err) => {
        console.error(`getConnectionsTranslations.error = ${err.message}`)
        this.loading = false
      })
    }
  }
}
</script>